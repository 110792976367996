import axios from 'axios';
import client from '@/utils/TypesenseConfig'
import { dbCollections } from '@/utils/firebaseCollections';
export const loopFunction = (arr, cb) => {
	let count = 0;
	let data = [];

	return new Promise((resolve, reject) => {
		try {
			function LoopFunction (val) {
				if(count === arr.length) {
					resolve(data);
					return;
				}
        if(val) {
          cb(val)
          .then((res) => {
            count++;
            data.push(res);
            LoopFunction(arr[count]);
          })
          .catch((error) => {
            console.error("ERROR in loopFunction: ", error.message);
            count++;
            LoopFunction(arr[count]);
          })
        } else {
          count++;
          LoopFunction(arr[count]);
        }
			}
			LoopFunction(arr[count]);
		} catch (error) {
			reject(error);
		}
	})
}

// export const getCommaSeperatedNumber = (number = 0) => {
//   let val = number.toString();
//   let tmp = ""

//   val = val.split("").reverse()
//   let after = 3
//   val.forEach((num, index) => {
//     if(index !== 0 && index === after){
//       tmp += ","
//       after = index + 2;
//     }
//     tmp += num
//   })

//   return tmp.split("").reverse().join("");
// }

export const getCommaSeperatedNumber = (n) => {
  let numVal = Number(n)
  return ' ' + numVal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

export const downloadDocument = (url,name) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
    
        fileLink.href = fileURL;
        fileLink.setAttribute('download',name);
        document.body.appendChild(fileLink);
    
        fileLink.click();

        resolve(true);
      })
      .catch(error=>{
        reject(error);
      })
    } catch (error) {
      reject(error);
    }
  })
};

const testloop = (dataArray,total, params, cb) =>{
  let count = 2;
  let totalFound = total;
  let finalArray = dataArray;
  const countFun = () =>{
      if(finalArray.length >= totalFound) {
        cb({
          finalArray: finalArray
        });
      } else {
        const searchParameters = {
            ...params,
            page: count
        }
        client.collections(dbCollections.TYPESENSE_PROPERTIES)
            .documents()
            .search(searchParameters)
            .then((result) => {
                if(result.hits){
                    finalArray = finalArray.concat(result.hits);
                    console.log("finalArray", finalArray.length, "page", count)
                    count++;
                    countFun();
                } else {
                  cb({
                    finalArray: finalArray
                  });
                }
            }).catch((error) =>{
                console.error(error);
                cb({
                  finalArray: finalArray
                });
            })
      }
  }
  countFun();
}

export const checkAddress = (address, propertyId) => {
  return new Promise((resolve, reject) => {
      try {
        const searchParameters = {
            q: '*',
            filter_by: `status: [2, 3, 5] && state:= ${address.state} && isArchived:= false`,
            per_page: 200
        }
        client.collections(dbCollections.TYPESENSE_PROPERTIES)
            .documents()
            .search(searchParameters)
            .then((result) => {
                if(result.hits.length == 0) {
                    resolve({
                        isValid: true
                    });
                } else {
                    testloop(result.hits, result.found,searchParameters, (res) =>{
                        let properties = [];
                        console.log("finalArray", res.finalArray.length);
                        res.finalArray.forEach((doc) =>{
                            properties.push(doc.document);
                        })
                        let isValid = true;
                        let count = 0;
                        const countFun = (property) => {
                            if(count >= properties.length) {
                                resolve({
                                    isValid: isValid
                                });
                                return;
                            } else  {
                                if(!propertyId || (propertyId && propertyId !== property.id) ) {
                                  if(property.streetNumber.toLowerCase().trim() ===  address.streetNumber.toLowerCase().trim()) {
                                      if(property.streetName.toLowerCase().trim() === address.streetName.toLowerCase().trim()) {
                                          if((!property.unitOrLot && !property.unitOrLot) || (property.unitOrLot.toLowerCase().trim() === address.unitOrLot.toLowerCase().trim())) {
                                              if(property.city.toLowerCase().trim() === address.city.toLowerCase().trim()) {
                                                  if(property.zipCode.toLowerCase().trim() === address.zipCode.toLowerCase().trim()) {
                                                      isValid = false;
                                                      count = properties.length;
                                                      countFun(properties[count]);
                                                  } else {
                                                      count++;
                                                      countFun(properties[count]);
                                                  }
                                              } else {
                                                  count++;
                                                  countFun(properties[count]);
                                              }
                                          } else {
                                              count++;
                                              countFun(properties[count]);
                                          }
                                      } else {
                                          count++;
                                          countFun(properties[count]);
                                      }
                                  } else {
                                      count++;
                                      countFun(properties[count]);
                                  }
                                } else {
                                    count++;
                                    countFun(properties[count]);
                                }
                            }
                        };
                        countFun(properties[count]);
                    })
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            })
      } catch (error) {
          console.error(error);
          reject(error);
      }
  });
};