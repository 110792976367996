import { Client } from 'typesense';
console.log('process.env.VUE_APP_TYPESENSE_HOST',process.env.VUE_APP_TYPESENSE_HOST,process.env.VUE_APP_TYPESENSE_API_KEY);
const client = new Client({
    nodes: [{
      host: process.env.VUE_APP_TYPESENSE_HOST,
      port: '443',
      protocol: 'https'
    }],
    apiKey: process.env.VUE_APP_TYPESENSE_API_KEY,
    connectionTimeoutSeconds: 2
  })

export default client